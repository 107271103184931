.wave_container {
    width: 100%;
    height: 100%;
    font-size: 10px;
}

.wave-header {
    display: flex;
    font-size: 1.2em;
    color: #2b2b2b;
    border-bottom: 1px #dbdbdb solid;
}

.wave-list__column{
    font-size: 1.1em;
}

.wave-list__column--id {
    width: 100px;
    padding-left: 5px;
}

.wave-list__column--device {
    width: 100px;
    margin: 0px;
    padding: 0px;
}

.wave-list__column--time {
    width: 200px;
    margin: 0px;
    padding: 0px;
}

.wave-list__column--complete {
    width: 80px;
    margin: 0px;
    padding: 0px;
}

.wave-list__column--prediction {
    width: 120px;
    margin: 0px;
    padding: 0px;
}

.wave-list__column--notify {
    width: 50px;
    margin: 0px;
    padding: 0px;
}

.wave-list {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
    height: 100%;
}

.wave-list__wrapper {
    flex: 1;
    overflow: scroll;
    height: 100vh;
    margin-left: 10px;
    margin-right: 10px;
}

.wave-list__search {
    margin: 5px;
}

.wave-list__reload {
    margin: 0px 5px 0px 5px;
    cursor: pointer;
    font-size: 11px;
}

.wave-list__item--deselected {
    display: flex;
    height: 40px;
    padding: 0px;
    margin: 0px;
    color: #626262;
    border-bottom: 1px #ededed solid;
}

.wave-list__item--selected {
    display: flex;
    height: 40px;
    padding: 0px;
    margin: 0px;
    color: #626262;
    border-bottom: 1px #ededed solid;
    background: #f0f4fd;
}

.wave-list__item--deselected:hover {
    background-color: #f0f4fd;
}

.wave-list__container__loading {
    display: flex;
    justify-content: left;
    width: 100%;
    height: 30px;
    padding: 0px;
}

.wave-detail__container--show {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    border-top: 1px solid grey;
    transition: all .3s cubic-bezier(0, 1, 0.5, 1);
}

.wave-detail__statistics {
}

.wave-detail__statistics__title {
    display: flex;
    flex-flow: row;
    background-color: #333333;
    color: #FAFAFA;
    font-size: 12px;
}

.wave-detail__statistics__values {
    display: flex;
    flex-flow: row;
    border-bottom: 1px solid #ededed;
    height: 25px;
    font-size: 12px;
}

.wave-detail__statistics__column {
    width: 80px;
    text-align: center;
    line-height: 30px;
}