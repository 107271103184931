.unauthorize {
  display: flex;

  width: 100%;
  height: 100%;

  justify-content: center;

  flex-direction: column;
  align-items: center;
}

.unauthorize > object:first-child {
  width: 60px;
  height: 60px;
}

.unauthorize > object:nth-child(2) {
  height: 32px;
}

.unauthorize > div:last-child {
  display: flex;

  width: 300px;
  height: 50px;

  margin-top: 30px;

  align-items: center;
  justify-content: center;

  color: #F4F4F4;
  background-color: #151B20;
  border-radius: 7px;
}

.unauthorize > div:last-child:hover {
  cursor: pointer;
  background-color: #37404C;
}

.unauthorize > div > p {
  padding: 0px;
  margin: 0px;

  font-size: 0.9em;
}