.trend_chart__container {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    background-color: #37404C;
}

.trend_chart__container::-webkit-scrollbar {
    width: 10px;
}

.trend_chart__container::-webkit-scrollbar-thumb {
    background: #161b1f;
    border-radius: 5px;
}

.trend_chart__container::-webkit-scrollbar-track {
    background: #35404d;
}

.trend_chart__title_container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;

    padding: 0px;
    margin: 0px;
}

.trend_chart__title {
    vertical-align: middle;
    margin: 0px 0px 0px 25px;
    padding: 20px 0px 10px 0px;

    font-size: 0.9em;

    color: #DCDCDD;
}

.trend_chart__chart {
    margin: 0px 25px 0px 25px;
    background-color: #202730;
    border-radius: 10px;

    width: calc(100% - 50px);
    height: 200px;
}