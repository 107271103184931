.main__container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.main__header {
    width: 65px;
    height: 100%;
}