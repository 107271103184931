.notification_item_container {
    position: relative;

    display: flex;
    flex-direction: row;

    background-color: white;

    margin: 10px 0px 10px 0px;
    padding: 10px 20px 10px 10px;

    font-size: 0.8em;
    border-radius: 8px;

    cursor: pointer;

    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid white;
}

.notification_item_container:hover {
    border: 1px solid #C1C1C1;
}

@keyframes slideout {
    from {
        left: 0px;
    }
    to {
        left: -500px;
    }
}

.closing {
    left: -500px;
    animation-duration: 0.5s;
    animation-name: slideout;
}

.notification_item__stack_count {
    position: absolute;
    top: -10px;
    right: 20px;

    display: flex;

    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    background-color: #EB4A26;
    border-radius: 10px;
}
.notification_item__stack_count > p {
    color: white;
    font-size: 0.7em;
    line-height: 20px;
}

.notification_item__close {
    position: absolute;

    top: 5px;
    right: 5px;

    width: 12px;
    height: 12px;

    opacity: 0;
}

.notification_item_container:hover > .notification_item__close {
    opacity: 1;
}

.notification_item__icon {
    width: 40px;
    margin-left: 5px;
    margin-right: 5px;
}
.notification_item__icon > img{
    margin: 5px;
}

.notification_item__msg_container {
    display: flex;

    height: 100%;
    width: 100%;
}

.notification_item__msg {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.notification_item__msg > p {
    padding: 0px;
    margin: 0px;
}

.notification_item__msg > p:last-child {
    padding-top: 8px;
    width: 210px;

    color: #9B9B9B;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}