.alarmlist_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-height: 100%;

    font-size: 12px;
    color: #DCDCDD;
    overflow-y: scroll;

    background-color: #37404C;
}


.alarmlist_no_content {
}

.alarmlist_no_content > p {
    padding: 0px;
    margin: 0px;

    text-align: center;
}

.alarmlist_container > div {
    margin: 10px;
}

.alarmlist_container > img {
    width: 50px;
    height: 50px;
}

.alarmlist_container > div {
    width: 100%;
}

.alarmlist_container::-webkit-scrollbar {
    width: 10px;
}

.alarmlist_container::-webkit-scrollbar-thumb {
    background: #161b1f;
    border-radius: 5px;
}

.alarmlist_container::-webkit-scrollbar-track {
    background: #35404d;
}

.alarmlist__row {
    display: flex;
    flex-direction: column;

    margin: 0px 20px 0px 20px;

    border-bottom: 1px solid #8B8E91;
}

.alarmlist__row > div:first-child {
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.alarmlist__row > div:first-child:hover {
    cursor: pointer;
    background-color: #676E77;
}

.alarmlist__row__info {
    display: flex;
    flex-direction: row;

    height: 30px;
}

.alarmlist__row__info > div {
    flex: 1;
}

.alarmlist__row__info > div:nth-child(2) {
    text-align: center;
}

.alarmlist__row__info--selected {
    display: flex;
    flex-direction: row;

    height: 30px;
}

.alarmlist__row__info--selected > div {
    flex: 1;
}

.alarmlist__row__info--selected > div:nth-child(2) {
    text-align: center;
}

.alarmlist__page_control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.alarmlist__page_control > div {
    margin: 0px 30px 10px 30px;
}

.alarmlist__page_control > div:hover {
    cursor: pointer;
}

.alarmlist__row__wave {
    background-color: #202730;
    border-radius: 7px;

    margin: 0px 5px 10px 5px;
}