.lora_device_container {
    display: flex;
    flex-direction: row;

    width: 100%;
    padding: 5px;
}

.lora_device__list_container {
    display: flex;
    flex-direction: column;

    border-right: 1px solid gray;
    overflow-y: scroll;
}

.lora_device_search {
    display: flex;
    flex-direction: row;
    min-height: 30px;
}

.lora_device_search > input[type="text"] {
}

.lora_device_entry {
    display: flex;
    flex-direction: column;

    width: 150px;
    min-height: 40px;

    font-size: 12px;
    padding: 5px;

    border-bottom: 1px solid #dbdbdb;
}

.lora_device_entry:hover {
    cursor: pointer;
    background-color: #151B21;
    color: white;
}

.lora_device_entry__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.lora_device_entry__title > div > p {
    margin: 0px;
    padding: 0px;
}

.lora_device_entry__info {
}

.lora_device_entry__info > p {
    font-style: italic;
    font-size: 10px;
    text-align: right;
    color: gray;
}

.lora_device_entry__info > p {
    margin: 0px;
    padding: 0px;
}

.lora_device_detail_container {
    flex: 1;
    padding: 5px;

    overflow-y: scroll;
}

.lora_device_detail--empty {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;

    justify-content: center;
    align-items: center;
}

.lora_device_detail--empty > p {
    padding: 0px;
    margin: 0px;
}

.lora_device_detail {
    display: flex;
    flex-direction: column;
}

.lora_device_detail__basic_info {
    margin: 5px;
}

.lora_device_detail__basic_info__info {
    display: flex;
    flex-direction: row;
}

.lora_device_detail__basic_info__info > div:first-child {
    background-color: #151B21;
    color: white;

    padding: 5px;
    min-width: 100px;
}

.lora_device_detail__basic_info__info > div:last-child {
    padding: 5px;
}

.lora_device_detail__basic_info__info > div > p {
    padding: 0px;
    margin: 0px;
}

.lora_device_detail__group {
    display: flex;
    flex-direction: column;

    font-size: 12px;
}

.lora_device_detail__title {
    color: white;
    background-color: #151B21;
}

.lora_device_detail__title > p {
    padding: 0px;
    margin: 0px;

    font-size: 14px;
}

.lora_device_detail__content {
    height: 300px;

    padding: 5px;
}

/* CommentView */
.comment_container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.comment_input {
    display: flex;
    flex-direction: row;
}

.comment_input > input[type="text"] {
    flex: 1;
    font-size: 12px;
    color: #151B21;
}

.comment_msg {
    overflow-y: scroll;
}

.comment_msg--empty {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.comment_msg--empty > p {
    padding: 0px;
    margin: 0px;
    font-style: italic;
}

.comment_msg__entry {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.comment_msg__entry__meta {
    margin: 2px;
    padding: 2px;

    width: 120px;
    font-size: 10px;
    color: gray;
    border-right: 1px solid gray;
}

.comment_msg__entry__meta > div > p {
    padding: 0px;
    margin: 0px;
}

.comment_msg__entry__msg {
    flex: 1;
    display: flex;

    padding: 2px;
}

.comment_msg__entry__msg > p {
    padding: 0px;
    margin: 0px;

    font-size: 11px;
    color: #151B21;
}

.comment_msg__entry__delete {
    width: 28px;
}