div {
    font-family: "Noto Sans KR";
}

.app_container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
}

.app_container__contents {
    flex: 1;
    height: 100%;
}
