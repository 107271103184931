.sideview_container {
    width: 100%;
    height:100%;

    background-color: #202730;
    color: #D5D5D6;
}

.sideview__brief {
    width: 100%;
    height: 180px;
}

.sideview__detail {
    width: 100%;
    height: calc(100% - 180px);

    background-color: #37404c;
}

.basic_info_container {
    display: flex;
    flex-direction: column;

    padding: 30px;
}

.basic_info__title {
    display: flex;
    flex-direction: row;

    margin: 5px 0px 5px 0px;
}

.basic_info__title > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.basic_info__title > div:first-child {
    font-size: 1.1em;
    font-weight: bold;
}

.basic_info__title > div:last-child {
    margin: 0px 10px 0px 10px;
    font-size: 0.8em;
}

.basic_info__title > div > p {
    padding: 0px;
    margin: 0px;

    text-align: center;
}

.basic_info__alias {
    font-size: 0.8em;
}

.basic_info__address {
    margin: 5px 0px 5px 0px;
    font-size: 0.7em;

    color: #A6ACB9;
}

.basic_info__alarm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 0px;
    padding: 0px;

    font-size: 0.8em;
}

.basic_info__alarm > p {
    padding: 0px;
    margin: 0px;

    color: #EB4626;
}

.basic_info__alarm > button {
    border: none;
    border-radius: 2px;;

    background-color: #EB4626;
    color: white;
    border: 1px solid red;
    outline: none;
}
.basic_info__alarm > button:active {
    opacity: 0.5;
}

.detail_info_container {
    width: 100%;
    height: 100%;
}