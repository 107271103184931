.stat_container {
    display: flex;
    flex-direction: column;

    font-size: 0.7em;
    color: #F9F9F9;

    border-radius: 10px;
    background-color: #37404C;
}

.stat__count {
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    padding: 7px;
}

.stat_entry {
    flex: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    margin-left: 5px;
    margin-right: 5px;

    width: 55px;
}

.stat_entry--select {
    flex: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    margin-left: 5px;
    margin-right: 5px;
    width: 55px;

    background-color: #202730;
    border-radius: 7px;
}

.stat_entry__title {
    padding: 0px;
    margin: 0px;

    text-align: center;
    font-weight: bolder;
}

.stat_entry__value {
    padding: 0px;
    margin: 0px;

    text-align: center;
}

.normal {
    color: #54B644;
}

.alarm {
    color: #EB4626;
}

.no_signal {
    color: #c2c2c2;
}

.error {
    color: #A6A7A8;
}

.uninstalling {
    color: #fc9700;
}

@keyframes slidedown {
    from {
        height: 0px
    }
    to {
        height: 150px
    }
}

.stat__list {
    display: flex;
    flex-direction: column;

    background-color: #202730;
    height: 150px;
    padding: 10px 0px 10px 0px;
    margin: 0px 0px 0px 0px;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    animation-duration: 0.1s;
    animation-name: slidedown;
}

.stat__list__scroll {
    overflow-y: scroll;
    color: white;
}

.stat__list__scroll::-webkit-scrollbar {
    width: 10px;
}

.stat__list__scroll::-webkit-scrollbar-thumb {
    background: #35404d;
    border-radius: 5px;
}

.stat__list__scroll::-webkit-scrollbar-track {
    background: #161b1f;
}

.status_dev_list_item_container {
    display: flex;
    flex-direction: row;

    height: 30px;
    
    align-items: center;
}

.status_dev_list_item_container:hover {
    cursor: pointer;
    background-color: #151b20;
}

.status_dev_list_item_container>div:first-child {
    width: 15px;
    height: 15px;

    margin: 0px 15px 0px 15px;
    border-radius: 10px;

    background-color: white;
}

.status_dev_list_item_container > div:nth-child(2){
    width: 50px;
}

.status_dev_list_item_container > div:last-child {
    display: block;

    width: calc(100% - 45px - 50px - 10px);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: #D5D5D6;
}