.g_detail_container {
    width: 100%;
    height: 100%;
}

.g_detail_info_container {
    width: 100%;
    height: 100%;

    padding: 20px;
}

.g_detail_info__row {
    display: flex;
    flex-direction: row;

    min-height: 32px;
    font-size: 12px;
    align-items: center;
}

.g_detail_info__row__title {
    width: 30%;
}

.g_detail_info__row__value {
    flex: 1;

    color: #A6ACB9;
}

/*  */
.g_acc_container {
    width: 100%;
    height: 100%;

    color: white;
    background-color: #37404c;
}

.acc__tension {
    margin: 10px;
}

.acc__tension > p {
    font-size: 13px;
    font-weight: bolder;
}

.acc__tension > div {
    background-color: #21272f;
    border-radius: 7px;
}

.g_acc__lists {
    margin: 20px 20px 5px 20px;
}

.g_acc__entity {
    border-bottom: 1px solid #a6acb9;
}

.g_acc__entity__title {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 30px;
}

.g_acc__entity__title--select {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 30px;
}

.g_acc__entity__title__name {
    flex: 1;
    margin: 0px 20px 0px 20px;

    font-size: 1.0em;
}

.g_acc__entity__title:hover {
    background-color: #21272f;
}

.g_acc__entity__chart_container {
    background-color: #21272f;
    border-radius: 3px;
}

.g_acc__entity__chart {
    display: flex;
    flex-direction: column;

    margin: 0px;
    padding: 0px;
}

.g_acc__entity__chart > p {
    margin: 5px 10px 5px 10px;
    font-size: 14px;
}

.g_acc__lists__page {
    display: flex;
    flex-direction: row;;
    align-items: center;

    padding: 10px;
}

.g_acc__lists__page > div {
    flex: 1;
    text-align: center;

    font-size: 0.9em;
    cursor: pointer;
}

/*  */
.distance_container {
    color: white;

    width: 100%;
    /* max-height: 500px; */

    background-color: #37404c;
}

.distance__chart {
    display: flex;
    flex-direction: column;

    height: 250px;
    font-size: 12px;

    margin: 20px;
}

.distance__chart > :last-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.distance__chart > p {
    margin: 15px 5px 5px 5px;
    font-size: 13px;
    font-weight: bolder;
}

.distance__chart > div {
    flex: 1;

    background-color: #21272f;
    border-radius: 7px;
}