.search_container {
    display: flex;
    flex-direction: column;

    width: 280px;

    border-radius: 7px;
    border: 1px solid #ABABAA;
    background-color: #FFFFFF;
}

.search__input {
    display: flex;
    flex-direction: row;

    align-items: center;

    width: 100%;
    height: 30px;
}

.search__input > img {
    padding: 0px;
    margin: 0px 6px 0px 6px;

    width: 18px;
}

.search__input > input {
    flex: 1;
    border: none;

    margin: 2px;

    font-style: italic;
}

.search__input > input::placeholder {
    color: #CBCBCB;
}

.search__input > input:focus {
    outline: none;
}

.search__input__clear {
    position: relative;
    width: 13px;
    height: 13px;

    border-radius: 6.5px;
    background-color: #DDDDDD;

    opacity: 0.5;

    margin-right: 10px;
}

.search__input__clear:hover {
    opacity: 1;
}

.search__input__clear:before, .search__input__clear:after {
    position: absolute;
    top: 3px;
    left: 6px;

    content: " ";

    height: 7px;
    width: 1px;
    background-color: #202730;
}

.search__input__clear:before {
    transform: rotate(45deg);
}

.search__input__clear:after {
    transform: rotate(-45deg);
}

.search__result {
    display: flex;
    flex-direction: column;

    padding: 10px 0px 10px 0px;

    overflow-y: scroll;
    min-height: 100px;
    max-height: 25vh;
    
    border-top: 1px solid #ABABAA;
}

.search__result::-webkit-scrollbar {
    width: 10px;
}

.search__result::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 5px;
}


.search__result__item {
    display: flex;
    flex-direction: row;

    align-items: center;
    min-height: 25px;

    padding-left: 10px;

    color: #4A525D;
    font-size: 12px;
}

.search__result__item > div:nth-child(1) {
    width: 14px;
    height: 14px;

    border-radius: 7px;
    background-color: #A8A8A8;
}

.search__result__item > div:nth-child(2) {
    margin-left: 10px;
    width: 50px;
}

.search__result__item > div:nth-child(3) {
    display: block;

    width: calc(100% - 10px - 14px - 10px - 50px);

    margin-left: 5px;
    padding: 0px;
    color: #A8A8A8;
}

.search__result__item > div:nth-child(3) > p {
    overflow: hidden;
    white-space: nowrap;

    margin: 0px;
    padding: 0px;
    text-overflow: ellipsis;
}

.search__result__item > div:last-child > img {
    margin-right: 5px;
    width: 10px;
    height: 10px;
    opacity: 0.4;
}

.search__result__item:hover {
    background-color: #E5E5E5;
}