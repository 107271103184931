.wavechart__title {
    display: flex;
    flex-direction: row;

    /* border: 1px solid red; */
}

.wavechart__title > p {
    padding: 0px;
    margin: 0px 20px 0px 0px;
}

/* Chart */
.wavechart__linechart {
    height: 200px;
}

/* Statistics */
.wavechart__trend {
    display: flex;
    flex-direction: column;

    margin: 0px;
    padding: 10px 30px 20px 30px;
}

.wavechart_container > div:last-child > div {
    display: flex;
    flex-direction: row;

    align-items: center;

    height: 25px;

    margin: 0px;
    padding: 0px;
}

.wavechart_container > div:last-child > div:first-child {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.wavechart_container > div:last-child > div > p {
    flex: 1;

    margin: 0px;
    padding: 0px;

    font-size: 0.7em;
    text-align: center;
}

/* Expand Button */
.expandbutton_container {
    position: relative;
    min-width: 20px;
    max-width: 20px;

    min-height: 20px;
    max-height: 20px;
    transition: all ease 0.2s;
}

.expandbutton_container--selected {
    position: relative;
    min-width: 20px;
    max-width: 20px;

    min-height: 20px;
    max-height: 20px;

    transform: rotate(180deg);
    transition: all ease 0.2s;
}

.expandbutton_container > svg {
    width: 20px;
    height: 20px;
}