.deviceinfo__info_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    overflow-y: auto;
}

.deviceinfo__info_container > div:first-child {
    margin-top: 15px;
}

.deviceinfo__info_container > div:last-child {
    margin-bottom: 15px;
}

.deviceinfo__row {
    display: flex;
    flex-direction: row;

    min-height: 32px;
    font-size: 12px;

    align-items: center;

    margin-left: 20px;
    margin-right: 20px;
}

.deviceinfo__row__title {
    width: 30%;

    margin: 0px;

    background-color: #37404C;
}

.deviceinfo__row__value {
    flex: 1;

    margin: 0px;
    color: #A6ACB9;
}

.deviceinfo__row__control {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.deviceinfo__row__control > button {
    border: none;
    border-radius: 2px;;

    background-color: #EB4626;
    color: white;
    border: 1px solid red;
    outline: none;
}

.deviceinfo__row__control > button:active {
    opacity: 0.5;
}

.deviceinfo__alarm__title {
    display: flex;
    flex-direction: row;

    height: 25px;

    padding: 5px 10px 5px 10px;

    font-size: 13px;
    font-weight: bold;

    background-color: #f0f4fd;
}

.deviceinfo__alarm_scroll_wrapper {
    flex: 1;
}

.deviceinfo__alarm_container {
    display: flex;
    flex-direction: column;

    margin: 0px;
    padding: 5px;

    font-size: 12px;
    color: #333333;
}

.deviceinfo__alarm__row {
    display: flex;

    width: 100%;
    height: 30px;

    border-bottom: 1px solid #ededed;
}

.deviceinfo__alarm__time {
    width: 40%;

    margin: 0px;
    padding: 0px;
}

.deviceinfo__alarm__type {
    flex: 1;

    margin: 0px;
    padding: 0px;
}

.deviceinfo__alarm__page {
    display: flex;
    flex-direction: row;
}

/* GroupSelectComponents */
.deviceinfo__group_select {
    display: flex;
    flex-direction: row;
}

.deviceinfo__group_select > select {
    font-size: 10px;
}

.deviceinfo__group_confirm {
    display: flex;
    flex-direction: row;

    font-size: 11px;
}

.deviceinfo__group_confirm > p {
    padding: 0px;
    margin: 0px 5px 0px 5px;
}


.deviceinfo__group_confirm > p:hover {
    cursor: pointer;

    padding: 0px;
    margin: 0px 5px 0px 5px;
}