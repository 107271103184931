.report_container {
    flex: 1;
    display: flex;
    flex-direction: column;

    height: 100%;
}

/*  Report header */
.report__header {
    display: flex;
    flex-direction: row;

    align-items: center;

    width: 100%;
    height: 60px;

    background-color: #31343D;
    color: #FFFFFF;
}

.report__title {
    margin: 0px 20px 0px 20px;
}

.report__type {
    display: flex;
    flex-direction: row;
}

.report__title {
    display: flex;
    flex-direction: column;
}

.report__title > p {
    padding: 0px;
    margin: 0px;

    color: #FFFFFF;
    font-size: 1.3em;
    font-weight: bolder;
}

.report__type > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 0px 10px 0px 10px;
}

.report__type > div:first-child > p {
    padding: 0px;
    margin: 0px;

    color: #D6DBE1;
}

.report__type > div:last-child {
    margin: 0px;
    padding: 0px;
}

.report__type > div:last-child > select {
    margin: 0px;
    padding: 0px;

    appearance: none;
    outline: none;

    color: #FFFFFF;
    font-size: 0.9em;

    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #FFFFFF;
    background-color: transparent;
}

.report__spacer {
    flex: 1;
}

.report__group {
    display: flex;
    flex-direction: row;
}

.report__group > div > p {
    margin: 0px;
    padding: 0px;
}

.report__group > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.report__group > div:first-child > p {
    font-weight: bolder;
}

.report__group > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0px 20px 0px 20px
}

.report__group > div:last-child > p {
    color: #D6DBE1;
}

/* AlertReport */
.alert_report__container {
    width: 100%;
    height: calc(100% - 60px);
}

/* Filters */
.report__filter {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 50px;

    background-color: #242424;
    color: #FFFFFF;
}

/* Filters - AlertType */
.report__filter__alert_type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: 0px 20px 0px 20px;
}

.report__filter__alert_type > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0px 10px 0px 10px;
}

.report__filter__alert_type > div > p {
    margin: 0px;
    padding: 0px;
}

.report__filter__alert_type > div:last-child > select {
    appearance: none;

    background-color: #242424;
    color: #FFFFFF;

    height: 30px;
    border-radius: 10px;

    padding: 0px 10px 0px 10px;

    cursor: pointer;
    outline: none;
    font-size: 0.9em;
}

/* Filters - DateRange */
.report__filter__date_range {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.report__filter__date_range > div {
    margin: 0px 10px 0px 10px;
}

.report__filter__date_range > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.report__filter__date_range > div > p {
    margin: 0px;
    padding: 0px;
}

.report__filter__date_range > div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.report__filter__date_preset {
    display: flex;
    flex-direction: row;

    align-items: center;
}

.report__filter__date_preset > label {
    margin:0px 5px 0px 5px;
    padding: 2px 10px 2px 10px;
    border: 1px solid #727C8B;
    border-radius: 20px;

    font-size: 0.8em;

    cursor: pointer;
}

.report__filter__date_preset > input[type="radio"] {
    display: none;
}

.report__filter__date_preset > input[type="radio"]:checked + label {
    background-color: #343741;
}

.report__filter__apply {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 50px;
}

.report__filter__apply > button {
    padding: 8px 15px 8px 15px;
    margin: 0px;

    /* margin-left: 100px; */
    border-radius: 20px;;
    border: 1px solid #727C8B;
    outline: none;

    font-size: 1em;
    color: #FFFFFF;
    background-color: #343741;
    cursor: pointer;
}

/* DateSelector */
.dateselector_container {
    display: flex;
    flex-direction: row;

    align-items: center;

    padding: 5px 15px 5px 15px;
    margin: 0px;

    border: 1px solid white;
    border-radius: 5px;
}

.dateselector__date {
    padding: 0px;
    margin: 0px 20px 0px 0px;

    font-size: 0.8em;
}

.dateselector__icon {
    width: 16px;
    height: 16px;
}

/* Report Data */
.report__data {
    height: calc(100% - 50px);
    overflow-y: auto;

    background-color: #31343D;
}

.report__data::-webkit-scrollbar {
    width: 10px;
}
.report__data::-webkit-scrollbar-thumb {
    background: #161b1f;
    border-radius: 5px;
}

/* Charts */
.report__chart {
    height: 600px;

    display: flex;
    flex-direction:  column;
    
    margin: 30px 100px 30px 100px;
}

.report__chart__summary {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.report__chart__summary__pie {
    flex: 1;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;

    background-color: #3C3F49;
    border-radius: 5px;

    margin: 10px;
}

.report__chart__summary__pie > p {
    padding: 0px;
    margin: 10px 0px 0px 20px;
    
    color: #FFFFFF;
}

.report__chart__summary__pie > div {
    flex: 1;
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    color: #727C8B;
}

.report__chart__summary__bar {
    flex: 3;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;

    background-color: #3C3F49;
    border-radius: 5px;

    margin: 10px;
}

.report__chart__summary__bar > p {
    padding: 0px;
    margin: 10px 0px 0px 20px;

    color: #FFFFFF;
}

.report__chart__summary__bar > div {
    flex: 1;
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    color: #727C8B;
}

/* Chart Trend */
.report__chart__trend {
    flex: 1;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;

    background-color: #3C3F49;
    border-radius: 5px;

    margin: 10px;
}

.report__chart__trend > p {
    padding: 0px;
    margin: 10px 0px 0px 20px;

    color: #FFFFFF;
}

.report__chart__trend > div:nth-child(3) {
    color: #727C8B;
}

.report__chart__trend__align_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding: 0px;
    margin: 0px;

    color: #A1A5AC;
}

.report__chart__trend__align {
    display: flex;
    flex-direction: row;

    padding: 0px;
    margin-right: 50px;
}

.report__chart__trend__align > input[type="radio"] {
    display: none;
}

.report__chart__trend__align > label {
    padding: 3px 15px 3px 15px;
    cursor: pointer;
}

.report__chart__trend__align > input[type="radio"]:checked + label {
    background-color: #2F323B;
}

.report__chart__trend__align > label:nth-child(2) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #A1A5Ac;
}

.report__chart__trend__align > label:nth-child(4) {
    border-top: 1px solid #A1A5AC;
    border-bottom: 1px solid #A1A5AC;
}

.report__chart__trend__align > label:nth-child(6) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #A1A5AC;
}

.report__chart__trend > div:last-child {
    flex: 1;
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
}

.report__list {
    display: flex;
    flex-direction: column;

    margin: 50px;
    height: 500px;

    color: #FFFFFF;
}

.report__list__summary > p {
    padding: 0px;
    margin: 0px 30px 20px 30px;
}

.report__list__header {
    display: flex;
    flex-direction: row;

    background-color: #343741;
}

.report__list__header > div {
    flex: 1;
    padding: 10px;
    border-bottom: 1px solid #7B7B7B;
}

.report__list__header > div > p {
    padding: 0px;
    margin: 0px;
    text-align: center;
    font-size: 0.9em;
    font-weight: bolder;
}

.report__list__header > div:first-child {
    max-width: 40px;
}

.report__list__items {
    overflow: auto;
    background-color: #3C3F49;
    color: #727C8B;
}

.report__list__items--empty {
    text-align: center;
}

.report__list__items::-webkit-scrollbar {
    width: 10px;
}
.report__list__items::-webkit-scrollbar-thumb {
    background: #161b1f;
    border-radius: 5px;
}

/* AlertItem */
.report__alert_item {
    display: flex;
    flex-direction: row;

    height: 40px;

    color: white;
    background-color: #343741;
    border-bottom: 1px solid #65676A;
}

.report__alert_item > div {
    flex: 1;
    display: flex;
    flex-direction: column;

    justify-content: center;

    margin: 0px 10px 0px 10px;
}

.report__alert_item > div > p {
    padding: 0px;
    margin: 0px;

    font-size: 0.8em;
    text-align: center;
}

.report__alert_item > div:first-child {
    max-width: 40px;
}

.report__list__wave {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.report__list__wave > button {
    padding: 5px 15px 5px 15px;

    border: 1px solid #666E7C;
    border-radius: 15px;
    outline: none;

    color: white;
    background-color: #343741;
    font-size: 1em;
}

.report__list__wave > button:hover {
    background-color: #2B2E36;
}

/* LoadingReport */
.loading_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: calc(100% - 65px);
    height: calc(100% - 60px);

    z-index: 1;

    opacity: 0.7;
    color: white;
    background-color: black;
    pointer-events: fill;
}

.loading_container > p {
    text-align: center;
}

/* WaveChartModal */
.wave_chart_modal_container {
    position: absolute;
    display: flex;

    justify-content: center;
    align-items: center;

    top: 0px;
    left: 65px;

    width: calc(100% - 65px);
    height: calc(100% - 60px);

    z-index: 1;
}

.wave_chart_modal__chart_area {
    display: flex;
    flex-direction: column;

    position: relative;

    width: 50%;
    height: 400px;

    padding: 30px;
    background-color: #3C3F49;
    border-radius: 12px;
    border: 1px solid #8B93A1;
    color: white;
}

.wave_chart_modal__close {
    position: absolute;
    top: 10px;
    right: 10px;

    width: 20px;
    height: 20px;

    /* border: 1px solid red; */
}

.wave_chart_modal__chart_area__title {
    display: flex;
    flex-direction: row;

    /* border: 1px solid red; */
}

.wave_chart_modal__chart_area__title > p {
    margin: 0px 50px 0px 0px;
    padding: 0px;
}

.wave_chart_modal__chart_area__title > p:last-child {
    flex: 1;
}

.wave_chart_modal__chart_area__chart {
    /* border: 1px solid red; */
}