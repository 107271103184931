.group_container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.group_lane_container {
    flex: 1;
    display: flex;
    flex-direction: row;

    overflow-x: scroll;
}

.group__lane {
    display: flex;
    flex-direction: column;

    min-width: 250px;
    overflow-y: scroll;

    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    color: #2b2b2b;
}

.group__lane--empty {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-width: 250px;

    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    overflow-y: scroll;
    color: #2b2b2b;

    text-align: center;
}

.group__lane--empty > p {
    padding: 30px 0px 30px 0px;
    margin: 0px;
}

.group__lane__entry {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 5px 10px 5px 10px;

    border-bottom: 1px solid grey;
}

.group__lane__entry--selected {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 5px 10px 5px 10px;

    border-bottom: 1px solid lightgray;
    background: #151B21;
    color: white;
}

.group__lane__entry:hover {
    background: gray;
    color: white;

    cursor: pointer;
}

.group__lane__entry__name {
    padding: 0px;
    margin: 0px;
}

.group__lane__entry__delete:hover {
    margin: 0px 5px 0px 5px;
    padding: 0px;

    font-weight: bold;
}

.group__lane__new {
    padding: 1px;

    text-align: center;
    font-weight: bold;
    color: #064389;

    cursor: pointer;
}

/* GroupAddComponents */
.group_add_container {
    padding: 2px;
}

.group_add_container > p {
    padding: 0px;
    margin: 0px;
}

.group_add__input {
    display: flex;
    flex-direction: row;
}

.group_add__input > p {
    margin: 0px;
    padding: 0px;
}

.group_add__input__spacer {
    flex: 1;
}

.group_detail_container {
    flex: 1;
    display: flex;
    flex-direction: row;

    width: 100%;
    max-height: 50%;
}

.group_detail__members {
    flex: 1;

    margin: 5px;
    border: 1px solid grey;
}

.group_detail__devices {
    flex: 1;

    margin: 5px;
    border: 1px solid grey;
}

/* Menu */
.menu_container {
    position: fixed;

    padding: 0px;

    background: white;
    border: 1px solid gray;
}

.menu__item {
    padding: 2px 5px 2px 5px;
}

.menu__item:hover {
    cursor: pointer;
    background: gray;
}

.menu__item > p {
    padding: 0px;
    margin: 0px;
}

/* Members */
.member_container {
    display: flex;
    flex-direction: column;
    height: 100%;

    overflow-y: scroll;
}

.member_container--empty {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
}

.member__loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

.member__list {
    overflow-y: scroll;
}

.member__list__entry {
    display: flex;
    flex-direction: row;

    margin: 2px;
    padding: 5px;
    border-bottom: 1px solid grey;
}

.member__list__entry > p {
    padding: 0px;
    margin: 0px 10px 0px 10px;
}

.member_empty {
    margin: 0px;
    padding: 0px;
    text-align: center;
}

/* Devices */
.device_container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
}

.admin_group_device__msg {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.admin_group_device__msg > p {
    margin: 0px;
    padding: 0px;
}

.admin_group_device__list_container {
    height: 100%;

    overflow-y: auto;
}

.admin_group_device__header {
    display: flex;
    flex-direction: row;

    background-color: #2b2b2b;
    color: white;
}

.admin_group_device__header > p {
    margin: 5px 10px 5px 10px;
    padding: 0px;
}

.admin_group_device__list {
    display: flex;
    flex-direction: row;
}

.admin_group_device__list > p {
    margin: 5px 10px 5px 10px;
    padding: 0px;
}

.admin_group_device__list > p:first-child {
    width: 180px;
}