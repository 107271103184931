.cls-1 {
    fill: #a8a8a8;
}

.cls-2 {
    fill: #f9fafb;
}
.trash_button__container:hover {
    cursor: pointer;
}

.trash_button__container:hover .cls-2 {
    fill: #414143;
}