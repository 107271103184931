@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.circle_loading_container {
    border-radius: 50%;
    animation: rotating 1s linear infinite;
}