html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
}

#root {
  width: 100%;
  height: 100%;
}
