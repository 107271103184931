.admin_container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

}

.admin_title {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 50px;

    justify-content: center;
}

.admin_title > p {
    padding: 0px;
    margin: 0px 10px 0px 10px;

    font-size: 16px;
}

.admin_content {
    width: 100%;
    height: calc(100% - 50px);
}