.header_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;

    vertical-align: middle;

    background-color: #151b20;
}

.header_container > div:nth-child(2) {
    flex: 1;
    display: flex;
    flex-direction: column;

    justify-content: center;
}

.header__launcher {
    position: relative;
    margin-top: 10px;
    padding-top: 100%;
}

.header__launcher_link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 6px;
    margin: 10px;

    background-color: white;
    border-radius: 5px;
}

.header__launcher_link > img {
    width: 100%;
    height: 100%;
}

/* 
    Menu items
 */
.header__menu_item_container {
    position: relative;

    width: 100%;
    padding-top: 100%;
}

.header__menu_link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.header__menu_link > div {
    display: flex;

    width: 40px;
    height: 40px;

    align-items: center;
    justify-content: center;

    background-color: #202730;
    border-radius: 7px;
}

.header__menu_link > div > img {
    width: 25px;
    height: 25px;
}

.header__menu_link > div:hover {
    background-color: #37404C;
}

.header__menu_link > div > p {
    margin: 0px;
    padding: 0px;
    font-size: 11px;
    color: #DCDCDD;
}

.app__header__sound {
    display: flex;

    flex-direction: col;
    justify-content: center;

    color: white;
}

.app__header__sound > div {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    margin-bottom: 20px;

    width: 32px;
    height: 32px;

    border-radius: 15px;
}

.app__header__sound > div:hover {
    background-color: #37404C;
}

.app__header__sound > div > img {
    padding: 0px;
    margin: 0px;

    width: 27px;
    height: 27px;
}

/* 
    Inbox button.
*/
.app__header__inbox {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 20px;
}

.app__header__inbox > img {
    width: 20px;
    height: 20px;

    padding: 6px;

    border-radius: 16px;
}

.app__header__inbox > img:hover {
    background-color: #37404C;
}

.app__header__inbox__new {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    top: 0px;
    right: 16px;

    width: 14px;
    height: 14px;

    margin: 0px;
    padding: 0px;
}

.app__header__inbox__new > img {
    padding: 0px;
    margin: 0px;

    color: white;
    width: 10px;
    height: 10px;
}

/* 
    LogIn / LogOut button
 */
.app__header__logbutton {
    position: relative;
    padding-top: 100%;
    width: 100%;
}

.app__header__logbutton > div {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.app__header__logbutton > div > button {
    width: 80%;
    height: 80%;

    background-color: #37404C;
    color: #DCDCDD;

    border-radius: 7px;
    border: none;
    font-size: 10px;
}

.app__header__logbutton > div > button:hover {
    cursor: pointer;

    background-color: #202730;
}

.app__header__logbutton > div > button:focus {
    outline: none;
}