.navermap_container {
    position: relative;

    display: flex;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
}

.navermap__wrapper {
    position: relative;
    flex: 1;
}

#navermap {
    width: 100%;
    height: 100%;
}

.navermap__info_panel {
    position: absolute;
    top: 20px;
    left: 20px;

    display: flex;
    flex-direction: column;
}

.navermap__statistics {
    position: absolute;
    top: 20px;
    right: 30px;
}

/* 
    Side info panel for DeviceDetailInfo.
 */
@keyframes slidein {
    from {
        width: 0px;
    }
    to {
        width: 400px;
    }
 }

.map__device_detail_container {
    width: 450px;
    height: 100%;

    animation-duration: 0.1s;
    animation-name: slidein;
}

/*
    Notification list panel.
*/

.map__notification_container {
    position: absolute;

    left: 25px;
    bottom: 20px;

    /* margin: 30px; */
    width: 300px;
}