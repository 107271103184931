.lora_data_container {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
    width: 100%;

    font-size: 12px;
}

.lora_data_container__search {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 25px;
    padding: 5px;
}

.lora_data__reload {
    cursor: pointer;

    padding: 0px;
    margin: 0px 10px 0px 10px;
}

.lora_data_container__list--header {
    display: flex;
    flex-direction: row;
    margin: 5px;
    padding: 0px;
    font-weight: bold;
    color: #2b2b2b;
    border-bottom: 1px #dbdbdb solid;
}

.lora_data_container__list__wrapper {
    flex: 1;
    overflow-y: auto;
    margin: 5px;
    padding: 0px;

    font-size: 11px;
}

.lora_data_container__list {
    display: flex;
    flex-direction: column;
}

.lora_data_container__list--list {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #626262;
    border-bottom: 1px #ededed solid;
}

.lora_data_container__list--list:hover {
    background-color: #f0f4fd;
}

.lora_data_container__list__id {
    width: 100px;
}

.lora_data_container__list__create {
    width: 150px;
}

.lora_data_container__list__latency{
    width: 80px;
}

.lora_data_container__list__desc {
    flex: 1;
}

.lora_data_container__list__desc--raw {
    color: #bbbbbb;
}

.lora_data_container__loading {
    display: flex;
    justify-content: left;
    width: 100%;
    height: 30px;
    padding: 0px;
    font-size: 12px;
}