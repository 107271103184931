.close_button_container {
    position: relative;

    width: 100%;
    height: 100%;

    opacity: 0.3;
}

.close_button_container > div:nth-child(1) {
    position: absolute;
    top: calc(20% / 2);
    left: calc(50% - 1px);

    width: 2px;
    height: 80%;

    transform: rotate(45deg);
}

.close_button_container > div:nth-child(2) {
    position: absolute;
    top: calc(20% / 2);
    left: calc(50% - 1px);

    width: 2px;
    height: 80%;

    transform: rotate(-45deg);
}

.close_button_container:hover {
    opacity: 0.8;
}