@keyframes right_slicein{
    from {
        width: 0px;
    }
    to {
        width: 400px; 
    }
}

.inbox_container {
    position: absolute;
    z-index: 999; 
    display: flex;
    flex-direction: row;

    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;
}

.inbox_content {
    position: relative;

    width: 420px;
    height: 100%;

    background-color: white;
    opacity: 1;

    animation-name: right_slicein;
    animation-duration: 0.1s;
}

.inbox_padding {
    flex: 1;
    background-color: black;
    opacity: 0.5;
}

.inbox_close {
    position: absolute;
    top: 5px;
    right: 5px;

    width: 22px;
    height: 22px;
}

.inbox_title {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 20px;

    height: 30px;
    font-size: 0.9em;
}

.inbox_title > p {
    padding: 0px;
    margin: 0px;
}

.inbox_menu {
    display: flex;
    flex-direction: row;

    margin-left: 20px;
    margin-right: 20px;
    height: 25px;
}

.inbox_menu__item {
    width: 50px;
    border-bottom: 2px solid #37404C;
}

.inbox_menu__item > p {
    padding: 0px;
    margin: 0px;

    width: 100%;
    text-align: center;

    font-size: 0.8em;
}

.inbox_menu > div:last-child {
    flex: 1;
    border-bottom: 1px solid #ABAFB4;
}

.inbox_control {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    margin: 0px 25px 0px 25px;
    height: 30px;
}

.inbox_control p {
    margin: 0px;
    padding: 0px;

    color: #AFAFAF;
    font-size: 0.75em;
}

.inbox_control__date {
}

.inbox_control__clear {
    cursor: pointer;
}

.inbox_msg {
    overflow-y: auto;
    padding-left: 30px;
    padding-right: 20px;

    height: calc(100% - 20px - 30px - 20px - 25px - 30px);
}

.inbox_msg__nomsg_container {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    padding: 30px;

    font-size: 0.9em;
    color: #36393c;
}

.inbox_msg__nomsg {
    width: 30px;
    height: 30px;
}

.inbox_msg::-webkit-scrollbar {
    width: 10px;
}

.inbox_msg::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 5px;
}

/* 
    InboxAlarmMsg
 */
.inbox_msg_container {
    position: relative;

    display: flex;
    flex-direction: column;

    padding-top: 10px;
    padding-bottom: 10px;

    overflow-y: hidden;

    transition-property: all;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.inbox_msg_closing {
    opacity: 0;
    padding: 0px;
    margin: 0px;
    height: 0px;
}

.inbox_msg_container > .inbox_msg_close {
    position: absolute;
    top: 5px;
    right: 5px;

    width: 35px;
    height: 35px;

    opacity: 0;
}

.inbox_msg_container:hover {
    background-color: #F9FaFb;
}

.inbox_msg_container:hover > .inbox_msg_close {
    opacity: 1;
}

.inbox_msg__msg_container {
    display: flex;
    flex-direction: row;

    align-items: center;
}

.inbox_msg__read_indicator {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    height: 100%;
    width: 10px;
}

.inbox_msg__read_indicator > div:first-child {
    width: 6px;
    height: 6px;

    border-radius: 3px;
    background-color: #37404C;
}

.inbox_msg__msg_icon {
    display: flex;

    width: 35px;
    height: 100%;

    align-items: center;
    justify-content: center;

    margin-left: 5px;
    margin-right: 5px;
}

.inbox_msg__msg_icon > img{
    width: 100%;
    height: 100%;
}

.inbox_msg__msg_body {
    display: flex;
    flex-direction: column;

    color: #25292C;
}

.inbox_msg__msg_title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.75em;

}

.inbox_msg__msg_title:hover {
    cursor: pointer;
}

.inbox_msg__msg_title > p {
    width: 260px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.inbox_msg__msg_title:hover > p {
    text-decoration: underline;
}

.inbox_msg__msg_title > p:first-child {
    padding: 0px;
    margin: 0px 0px 0px 5px;
}

.inbox_msg__msg_title > p:last-child {
    margin: 0px;
    padding: 0px 0px 0px 5px;
}

.inbox_msg__msg_desc {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9em;
}

.inbox_msg__msg_desc > p {
    padding: 0px;
    margin: 0px 2px 0px 5px;

    align-items: center;
    font-size: 0.85em;
}

.inbox_msg__time {
    margin-top: 5px;
    padding-left: 60px;
    font-size: 0.7em;
}
.inbox_msg__time > p{
    padding: 0px;
    margin: 0px;

    color: #66696B;
}