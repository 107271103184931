h1 {
    font-size: 14px;
    padding: 5px 10px 5px 10px;
}
.device_report__container {
    width: 100%;

    display: flex;
    flex-direction: row;
}

.device_report__device {
    flex: 2;
    overflow-y: scroll;
}

.device_report__device__list_container > div {
    display: flex;
    flex-direction: row;

    padding: 10px;
    border-bottom: 1px solid #dbdbdb;

    font-size: 14px;
}

.device_report_device__list_entry:hover {
    background: #f0f4fd;
}

.device_report_device__list_entry--select {
    background: #f0f4fd;
}

.device_report_device__list__devid {
    margin: 0px 5px 0px 5px;
}

.device_report_device__list__time {
    color: gray;
    font-size: 13px;

    margin: 0px 5px 0px 5px;
}

.device_report__install_list {
    flex: 2;
    border-left: 1px solid gray;
    border-right: 1px solid gray;

    font-size: 14px;
    overflow-y: scroll;
}

.device_report__install_list__container > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 10px;
    border-bottom: 1px solid #dbdbdb;
}

.device_report__install_list__entry--select {
    background: #f0f4fd;
}

.device_report__install_list__entry:hover {
    background: #f0f4fd;
}

/* Log entries */
h2 {
    font-size: 14px;
}
.device_report__install_details {
    flex: 4;
    overflow-y: scroll;
}

.device_report__install_details__container {
    font-size: 13px;;
    padding: 5px;
}

.device_report__install_details__environ {
    display: flex;
    flex-direction: row;
}

.device_report__install_details__environ  > div {
    padding: 5px;
}

.device_report__install_details__environ > div:first-child{
    flex: 1;
    color: white;
    background-color: black;
}

.device_report__install_details__environ > div:last-child{
    flex: 1;
}

.device_report__install_details__log {
    font-size: 13px;
}

.device_report__install_details__log__title {
    display: flex;
    flex-direction: row;
}

.device_report__install_details__log__title > div {
    flex: 1;
}

.device_report__install_details__log__log {
    display: flex;
    flex-direction: row;
}

.device_report__install_details__log__log > div{
    flex: 1;
}

/* InstallResultIndicator */
.install_result_indicator__container {
    width: 15px;
    height: 15px;

    margin: 5px;
    border-radius: 7.5px;
}

.install_result_indicator--success {
    background-color: green;
}

.install_result_indicator--fail {
    background-color: red;
}

.install_result_indicator--cancel {
    background-color: yellow;
}